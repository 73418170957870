import axios from "axios";
import productsJson from "./../../data/products.json";

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
// export const fetchProducts = products => {
//   return dispatch => {
//     dispatch(fetchProductsSuccess(products));
//   };
// };

export const fetchProducts = () => {
  return dispatch => {
    axios
    .get(process.env.PUBLIC_URL +'/product/get-all')
    .then(response => {
      if (response.status == "200" && response.data != "error")
      {
        dispatch(fetchProductsSuccess(response.data));
      }
     
      return response.status;
    })
    .catch(err => {
      dispatch(fetchProductsSuccess(productsJson));
    });
  };
};
