import axios from "axios";
import { Redirect, React } from 'react';
import SberbankIPay from 'react-sberbank-ipay';
import ReactPixel from 'react-facebook-pixel';

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";


//add to cart
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductColorDescription,
  selectedProductSize
) => {
  return dispatch => {
    ReactPixel.init(process.env.REACT_APP_PIXEL_ID);
    ReactPixel.trackCustom('AddToCart');

    if (addToast) {
      addToast("Добавлено в корзину", { appearance: "success", autoDismiss: true });
    }
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
          ? item.selectedProductColor
          : null,
        selectedProductColorDescription: selectedProductColorDescription
        ? selectedProductColorDescription
        : item.selectedProductColorDescription
        ? item.selectedProductColorDescription
        : null,
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
          ? item.selectedProductSize
          : null
      }
    });
  };
};

//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Количество уменьшено", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};

//delete from cart
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Товар удален из корзины", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};

//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Корзина очищена", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter(single => single.color === color)[0]
      .size.filter(single => single.name === size)[0].stock;
  }
};

// get order payment
export const orderPayment = (data, addToast, onSuccess) => {
  return dispatch => {
    SberbankIPay.ipayCheckout({
      amount: data["Price"],
      currency: 'RUB',
      order_number: data["Номер"],
      description: 'Оплата заказа номер ' + data["Номер"] + ' в магазине Comma'},
      function(order) {  
        if (onSuccess) { 
          onSuccess(data, addToast); 
        };
        addToast("Заказ успешно оплачен", {
        appearance: "success",
        autoDismiss: true
      });
     },
      function(order) { 
        addToast("Произошла ошибка. Пожалуйста, попробуйте еще раз или свяжитесь с менеджером", {
          appearance: "error",
          autoDismiss: true
        });
       }
    );
  };
}

// place order
export const placeOrder = (data, addToast, onSuccess) => {

  ReactPixel.init(process.env.REACT_APP_PIXEL_ID);
  ReactPixel.trackCustom('Purchase');

  return dispatch => {
    axios
      .post(process.env.PUBLIC_URL +'/main/send-telegram-message', data)
      .then(response => {
        if (response.status == "200")
        {
          addToast("Заказ успешно создан", {
            appearance: "success",
            autoDismiss: true
          });

          if (onSuccess) { 
            onSuccess(); 
          };
        }
        else{
          addToast("Произошла ошибка. Пожалуйста, свяжитесь с менеджером", {
            appearance: "error",
            autoDismiss: true
          });
        }
        return response.status;
      })
      .catch(err => {
        console.log("Error: ", err);

        addToast("Произошла ошибка. Пожалуйста, свяжитесь с менеджером", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };
 
}

// apply Promocode to the card
export const applyPromocode = (code, addToast, onSuccess) => {
  return dispatch => {
    axios
      .post(process.env.PUBLIC_URL +'/main/apply-promocode', code)
      .then(response => {
        if (response.status == "200" && response.data != "error")
        {
          addToast("Промокод на скидку " + response.data + "% успешно применен", {
            appearance: "success",
            autoDismiss: true
          });

          if (onSuccess) { 
            onSuccess(response.data); 
          };
        }
        else{
          addToast("Произошла ошибка. Промокод введён не верно или не актуален", {
            appearance: "error",
            autoDismiss: true
          });
        }
        return response.status;
      })
      .catch(err => {
        console.log("Error: ", err);

        addToast("Произошла ошибка. Пожалуйста, свяжитесь с менеджером", {
          appearance: "error",
          autoDismiss: true
        });
      });
  };

}
